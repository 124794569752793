export enum StorageItem {
  municipality = 'municipality',
  agency_id = 'agency_id',

  // mobiel only items
  token = 'token',
}

export const getItem = (itemName: StorageItem): string | null => {
  const item = localStorage.getItem(itemName);
  return item ? item : null;
};

export const setItem = (itemName: StorageItem, value: string): void => {
  localStorage.setItem(itemName, value);
};

export const removeItem = (itemName: StorageItem): void => {
  localStorage.removeItem(itemName);
};
