import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import Backend from 'src/app/core/backend';
@Component({
  selector: 'app-imgloader',
  template:
    '<img id="IMAGE_MODAL" style="object-fit : cover"   [class]="class" [style]="style" [attr.width]="width" [attr.height]="height" [src]="src" *ngIf="!hideIfInvalid || this.id !== \'0\' && this.id !== null && this.id !== \'\'"/>',
})
export class ImgloaderComponent implements OnChanges {
  @Input() id: string;
  @Input() class: string;
  @Input() style: string;
  @Input() height: number;
  @Input() hideIfInvalid = false;

  private album: any = [];
  @Input() width = 100;
  src: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.id) {
      this.src = '../../assets/images/dropzone/placeholder_elbaladiya.jpg';
    } else {
      this.src = Backend.url + this.id;
    }
    const image = {
      src: this.src,
      caption: '',
      thumn: '',
    };
    this.album.push(image);
  }
}
