import { Component, OnInit } from '@angular/core';
import { GenericTvItem } from 'src/app/core/models/class/GenericTvItem';
import { News } from 'src/app/core/models/class/news';
import { NewsService } from 'src/app/core/services/news.service';

@Component({
  selector: 'app-news-tv',
  templateUrl: './news-tv.component.html',
  styleUrls: ['./news-tv.component.css'],
})
export class NewsTvComponent extends GenericTvItem<News> implements OnInit {
  title = 'المستجدات البلدية';
  icon = 'assets/images/news-icon.png';

  constructor(protected _newsService: NewsService) {
    super(_newsService);
  }

  ngOnInit(): void {}
}
