
import { IEnv } from "./environment.interface"
export const environment:IEnv = {
   apiUrl: 'https://dev-backend.elbaladiya.tn',
   municipality: '216',
   agency: '7076cf07-4e89-4d9a-bce2-7d807fe519ca',
   appName: 'elBaladiya.tn',
   nodeEnv: 'production',
   production: true,
   bugsnagKey: 'wassef'
  };
