import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { defaultAnimation, setItem, StorageItem } from 'src/app/core';
import { Agency } from 'src/app/core/models/class/agency';
import { Service } from 'src/app/core/models/class/service';
import { ToastLuncherService } from 'src/app/core/services/toast-luncher.service';
import { WebsocketServiceMobile } from 'src/app/services/socket.service';
import { BaseView } from '../../core/models/class/BaseView.component';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css'],
  ...defaultAnimation,
})
export class MobileComponent implements OnInit {
  private readonly keepAliveInterval = 30000; // 30 seconds
  isLoading: boolean = true;
  selectedAgency: Agency = null;
  show: boolean = true;
  selectedService: Service = null;
  selectedServiceToReset: Service = null;
  services: Service[] = [];
  nextWindow: number = 1;
  windows = Array.from({ length: 10 }, (_, i) => i + 1); // array with content from 1 .. 10
  base: BaseView;
  constructor(
    public router: Router,
    protected _localService: LocalService,
    public _toastLuncherService: ToastLuncherService,
    public _websocketService: WebsocketServiceMobile,
  ) {
    this.base = new BaseView(this._localService);
    this.onServiceIncrement();
    setInterval(() => {
      this._websocketService.sendEmptyMessage();
    }, this.keepAliveInterval);
  }

  ngOnInit() {
    this._localService.getAgency().subscribe(agency => {
      this.setSelectedAgency(agency);
      this._localService.getServices().subscribe(services => {
        this.services = services;
        this.isLoading = false;
      });
    });
  }

  setSelectedAgency(agency: Agency) {
    this.selectedAgency = agency;
    setItem(StorageItem.agency_id, agency.id);
  }

  onSelecWindow($event) {
    this.nextWindow = $event.target.value;
  }

  callNext(service: Service) {
    this.isLoading = true;
    this._localService.callNext(service.id, this.nextWindow).subscribe(
      res => {
        this.selectedService = res;
        const idx = this.services.findIndex(s => s.id === res.id);
        if (idx !== -1) this.services[idx] = res;
        this._toastLuncherService.success({ text: 'تم استدعاء المواطن التالي' });
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        if (err.status === 406) throw new Error('لا مزيد من المواطنين في الصف.');
        throw new Error('لم نتمكن من دعوة المواطن التالي');
      },
    );
  }

  setSelectedService(service: Service) {
    this.selectedService = service;
    this.show = !this.show;
  }

  resetService() {
    this._localService.resetService(this.selectedServiceToReset.id).subscribe();
  }

  setSelectedServiceToReset(service: Service) {
    this.selectedServiceToReset = service;
  }

  public onServiceIncrement() {
    this._websocketService.onServiceUpdated().subscribe({
      next: async serviceChanged => {
        const idx = this.services.findIndex(s => s.id === serviceChanged.id);
        if (idx !== -1) {
          // update displayed number
          this.services[idx] = { ...this.services[idx], ...serviceChanged };
          if (this.selectedService?.id === serviceChanged?.id)
            this.selectedService = { ...this.selectedService, ...serviceChanged };
        }
      },
    });
  }
}
