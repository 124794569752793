import { Component, OnInit } from '@angular/core';
import { Comment } from 'src/app/core/models/class/comment';
import { GenericTvItem } from 'src/app/core/models/class/GenericTvItem';
import { ForumService } from 'src/app/core/services/forum.service';
import { getCommentTypeIcon } from '../../core';
import { UpdateTypes } from 'src/app/core/models/types';

@Component({
  selector: 'app-forum-tv',
  templateUrl: './forum-tv.component.html',
  styleUrls: ['./forum-tv.component.css'],
})
export class ForumTvComponent extends GenericTvItem<Comment> implements OnInit {
  title = 'المنتدى';
  icon = 'assets/images/forum-icon.png';

  constructor(protected _forumService: ForumService) {
    super(_forumService);
  }

  ngOnInit(): void {}

  preSettingData(data: Comment[]): Comment[] {
    return data.filter(
      c => c.updates[0].status === UpdateTypes.PROCESSING || c.updates[0].status === UpdateTypes.ACCEPTED,
    );
  }

  getCommentTypeIcon = getCommentTypeIcon;
}
