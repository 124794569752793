import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { defaultAnimation } from 'src/app/core';
import { Service } from 'src/app/core/models/class/service';
import { ToastLuncherService } from 'src/app/core/services/toast-luncher.service';
import { LocalService } from 'src/app/services/local.service';
import { BaseView } from '../../core/models/class/BaseView.component';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css'],
  encapsulation: ViewEncapsulation.None,
  ...defaultAnimation,
})
export class TerminalComponent implements OnInit {
  services: Service[] = [];
  base: BaseView;
  constructor(
    protected route: Router,
    protected _localService: LocalService,
    protected _toastLuncherService: ToastLuncherService,
  ) {
    this.base = new BaseView(this._localService);
  }

  ngOnInit() {
    this._localService?.getServices()?.subscribe(res => {
      this.services = res;
    });
  }

  print(service: Service) {
    this._localService.bookAndPrint(service.id).subscribe(
      serviceChanged => {
        const idx = this.services.findIndex(s => s.id === serviceChanged.id);
        this.services[idx] = serviceChanged;
        this._toastLuncherService.success({
          showConfirmButton: false,
          text: 'تم طباعة تذكرتك',
        });
      },
      err => {
        throw new Error('لم نتمكن من طباعة تذكرتك حاليًا');
      },
    );
  }

  removePrefix(serviceName: string): string {
    return typeof serviceName === 'string' && serviceName.includes('-') ? serviceName.split('-')[1] : serviceName;
  }
}
