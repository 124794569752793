import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Complaint } from '../models/class/complaint';
import { GenericReadOnlyRemoteService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class ComplaintsService extends GenericReadOnlyRemoteService<Complaint> {
  constructor(http: HttpClient) {
    super('/complaints', http);
  }
}
