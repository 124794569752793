import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { News } from '../models/class/news';
import { GenericReadOnlyRemoteService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends GenericReadOnlyRemoteService<News> {
  constructor(http: HttpClient) {
    super('/news', http);
  }
}
