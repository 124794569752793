import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import Backend from 'src/app/core/backend';
import { getItem, StorageItem } from '../lib/local-storage.utils';
import { isSecure } from '../lib/functions';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor() {}

  public getUrl(): string {
    const address = Backend.url;
    const proto = isSecure(address) ? 'https' : 'http';
    return `${proto}://${address}/api`;
  }

  public getHeaders(): HttpHeaders {
    const token = getItem(StorageItem.token);
    return this.getHeadersFromToken(token);
  }

  public getHeadersFromToken(token: string): HttpHeaders {
    const headers = new HttpHeaders();
    return token ? headers.append('Authorization', 'Bearer ' + token) : headers;
  }
}

export class ApplicationState {
  static getMunicipalityId(): string | null {
    const id = getItem(StorageItem.municipality);
    return typeof id === 'string' ? id : null;
  }

  static isAuthenticated(): boolean {
    return ApplicationState.getToken()?.length > 10 ? true : false;
  }

  static getToken(): string | null {
    return getItem(StorageItem.token);
  }
}
