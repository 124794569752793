import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SOUNDS, canAutoplayAudio, defaultAnimation, getPrefix } from 'src/app/core';
import { Service } from 'src/app/core/models/class/service';
import { ToastLuncherService } from 'src/app/core/services/toast-luncher.service';
import { LocalService } from 'src/app/services/local.service';
import { WebsocketService } from 'src/app/services/socket.service';
import { AudioQueue, SlidingContentComponent } from './tv.class';
import { BaseView } from 'src/app/core/models/class/BaseView.component';

class BaseTvComponent extends SlidingContentComponent {
  readonly keepAliveInterval = 120000; // 2 minutes
  services: Service[] = [];
  lastServiceCalledId = 0; // 0 always matches nothing
  audio: AudioQueue;
  base: BaseView;
  constructor(protected _localService: LocalService) {
    super(_localService);
    this.base = new BaseView(this._localService);
  }
}
@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.css'],
  encapsulation: ViewEncapsulation.None,
  ...defaultAnimation,
})
export class TvComponent extends BaseTvComponent implements OnInit, AfterViewInit {
  constructor(
    protected _localService: LocalService,
    private _websocketService: WebsocketService,
    protected _toastLuncherService: ToastLuncherService,
  ) {
    super(_localService);
    this.onServiceIncrement();
    this._localService.getServices().subscribe(res => {
      this.services = res;
    });
    setInterval(() => {
      if (!this.audio?.isPlaying) {
        this._websocketService.sendEmptyMessage();
      }
    }, this.keepAliveInterval);
  }

  ngOnInit() {
    if (canAutoplayAudio()) this.audio = new AudioQueue(SOUNDS.TEST_AUDIO);
    else
      this._toastLuncherService.askForSoundPermission().then(() => {
        this.audio = new AudioQueue(SOUNDS.TEST_AUDIO);
      });
  }

  onServiceIncrement() {
    this._websocketService.onServiceUpdated().subscribe({
      next: async serviceChanged => {
        const idx = this.services.findIndex(s => s.id === serviceChanged.id);
        if (
          idx !== -1 &&
          // so printing doesn't trigger sound
          this.services[idx]?.current_ticket !== serviceChanged?.current_ticket
        ) {
          // update displayed number
          this.services[idx] = { ...this.services[idx], ...serviceChanged };
          this.lastServiceCalledId = serviceChanged.id;
          const sounds = [
            getPrefix(this.services[idx]),
            serviceChanged.current_ticket.toString(),
            `WINDOW_${serviceChanged.next_window}`,
          ];
          for (const i in sounds) {
            this.audio?.addToQueue(sounds[i]);
          }
        }
      },
    });
  }
}
