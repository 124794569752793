import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export default class Backend {
  /* examples: window.location.host
  
    address = fouchena1.eu.ngrok.io (remote addr)
    address = 41.230.57.129:8080 (includes the port) (remote addr or local ip)
    address = 55.230.57.129 (or not) (remote addr) (remote addr or local ip)
*/
  static readonly url = environment.production ? window.location.host : 'localhost:8000';
  static readonly remote_url = environment.apiUrl;
  constructor() {}
}
