import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ToastLuncherService } from '../services/toast-luncher.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  static _toast: ToastLuncherService;

  constructor(public _toastLuncherService: ToastLuncherService) {
    TokenInterceptor._toast = this._toastLuncherService;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(retry(1), catchError(this.handelError));
  }

  private handelError(error: HttpErrorResponse) {
    // check it is a server-side error
    console.error(error.error.detail);
    /*

      TokenInterceptor._toast.info({
        showConfirmButton: false,
        text: error.error.detail,
      });
    */
    return throwError(error);
  }
}
