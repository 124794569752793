import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { MobileComponent } from './pages/mobile/mobile.component';
import { TerminalComponent } from './pages/terminal/terminal.component';
import { TvComponent } from './pages/tv/tv.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'mobile',
    canActivate: [AuthGuardService],
    component: MobileComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'borne',
    component: TerminalComponent,
  },
  {
    path: '**',
    component: TvComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
