import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getItem, setItem, StorageItem } from './core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  userAgent: string = navigator.userAgent;
  isLoggedIn = getItem(StorageItem.token)?.length > 10 ? true : false;
  constructor(protected router: Router) {
    this.setLocalCache();
    if (this.isMobileDevice() && !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {}

  setLocalCache() {
    setItem(StorageItem.municipality, environment.municipality);
    setItem(StorageItem.agency_id, environment.agency);
  }

  isMobileDevice = (): boolean => {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
    return regexs.some(b => this.userAgent.match(b));
  };

  isTabletDevice = (): boolean => {
    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return regex.test(this.userAgent.toLowerCase());
  };
}
