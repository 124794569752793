import { Component, ElementRef, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { LocalService } from 'src/app/services/local.service';

export enum autoScrollDirection {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export class AudioQueue {
  private audio: HTMLAudioElement = new Audio();
  private queue: string[] = [];
  public isPlaying: boolean = false;

  constructor(initialSoundName: string) {
    this.audio.addEventListener('ended', () => this.playNext());
    this.enqueue(initialSoundName);
  }

  private playNext() {
    if (this.queue.length > 0) {
      const nextSound = this.queue.shift();
      this.playAudio(nextSound);
    } else {
      this.isPlaying = false;
    }
  }

  private playAudio(fileName: string) {
    this.audio.src = `assets/audio/${fileName}.wav`;
    console.log('Playing audio:', fileName);
    this.audio.play().catch(error => console.error('Error playing audio:', error));
  }

  public enqueue(fileName: string) {
    this.queue.push(fileName);
    if (!this.isPlaying) {
      this.playNext();
      this.isPlaying = true;
    }
  }

  public addToQueue(fileName: string) {
    this.enqueue(fileName);
  }
}


@Component({
  template: ' <div class="col-md-5 scroll-container" #scrollframe></div>',
})
export class SlidingContentComponent {
  scrollFactor: number = 200;
  @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;
  scrollContainer: any;
  scrollPosition: number = 0;
  autoScrollDirection: autoScrollDirection = autoScrollDirection.BOTTOM;

  constructor(protected _localService: LocalService) {}

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.scrollFactor = this.scrollContainer.scrollHeight / 4;
    interval(10000).subscribe(val => {
      if (this.scrollPosition === 0) this.autoScrollDirection = autoScrollDirection.BOTTOM;
      if (this.scrollPosition > this.scrollContainer.scrollHeight) this.autoScrollDirection = autoScrollDirection.TOP;
      this.autoScroll();
    });
  }

  autoScroll(): void {
    if (this.autoScrollDirection === autoScrollDirection.BOTTOM) return this.scrollToBottom();
    return this.scrollToTop();
  }

  scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: (this.scrollPosition += this.scrollFactor),
      left: 0,
      behavior: 'smooth',
    });
  }

  scrollToTop(): void {
    this.scrollContainer.scroll({
      top: (this.scrollPosition -= this.scrollFactor),
      left: 0,
      behavior: 'smooth',
    });
  }
}
