import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler, InjectionToken, Injector, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from './core/auth/token.interceptor';

import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './core/auth/timeout.interceptor';
import { ToastLuncherService } from './core/services/toast-luncher.service';
import { LowerCaseUrlSerializer } from './core/services/lower-case-serializer.service';
import { TerminalComponent } from './pages/terminal/terminal.component';
import { TvComponent } from './pages/tv/tv.component';
import { OurCommonModule } from './core';
import { ForumTvComponent } from './components/forum-tv/forum-tv.component';
import { NewsTvComponent } from './components/news-tv/news-tv.component';
import { PlaintsTvComponent } from './components/plaints-tv/plaints-tv.component';
import { PollsTvComponent } from './components/polls-tv/polls-tv.component';
import { MobileComponent } from './pages/mobile/mobile.component';
import { LoginComponent } from './pages/login/login.component';
import { WebsocketService, WebsocketServiceMobile } from './services/socket.service';
import { environment } from 'src/environments/environment';

Bugsnag.start({ apiKey: environment?.bugsnagKey?.length ? environment.bugsnagKey : 'undefined' });

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

@Injectable({ providedIn: 'root' })
export class CombineErrorHandlers implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: any): any {
    const handlers = this.injector.get<ErrorHandler[]>(ERROR_HANDLERS, []);
    handlers.forEach((errorHandler: ErrorHandler) => {
      errorHandler.handleError(error);
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    TerminalComponent,
    TvComponent,
    MobileComponent,
    LoginComponent,
    ForumTvComponent,
    NewsTvComponent,
    PlaintsTvComponent,
    PollsTvComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    OurCommonModule,
  ],
  providers: [
    { provide: DEFAULT_TIMEOUT, useValue: 25000 },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    {
      provide: ERROR_HANDLERS,
      useExisting: ToastLuncherService,
      multi: true,
    },
    {
      provide: ERROR_HANDLERS,
      useFactory: errorHandlerFactory,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useExisting: CombineErrorHandlers,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    WebsocketService,
    WebsocketServiceMobile,
    JwtHelperService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
platformBrowserDynamic().bootstrapModule(AppModule);
