import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Service } from '../core/models/class/service';
import { Municipality } from '../core/models/class/municipality';
import { Agency } from '../core/models/class/agency';
import { BaseService } from '../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class LocalService extends BaseService {
  prefix = '/services/';
  constructor(public http: HttpClient) {
    super();
  }

  getServices(): Observable<Service[]> {
    return this.http.get(this.getUrl() + this.prefix, { headers: new HttpHeaders() }) as Observable<Service[]>;
  }

  bookAndPrint(serviceId: number): Observable<Service> {
    return this.http.post(this.getUrl() + this.prefix + `${serviceId}` + '/book-and-print/', {}) as Observable<Service>;
  }

  loginOnlyPhone(phone_number: string, password: string) {
    return this.http.post(this.getUrl() + '/login/', { phone_number, password }) as Observable<any>;
  }

  getMunicipality(): Observable<Municipality> {
    return this.http.get(this.getUrl() + '/municipality/', { headers: new HttpHeaders() }) as Observable<Municipality>;
  }

  getAgency(): Observable<Agency> {
    return this.http.get(this.getUrl() + '/agency/', { headers: new HttpHeaders() }) as Observable<Agency>;
  }

  callNext(serviceId: number, next_window: number): Observable<Service> {
    return this.http.post(
      this.getUrl() + `/services/${serviceId}/call-next-booking/`,
      {
        next_window,
      },
      {
        headers: this.getHeaders(),
      },
    ) as Observable<Service>;
  }

  resetService(serviceId: number) {
    return this.http.post(
      this.getUrl() + `/services/${serviceId}/reset-tickets/`,
      {},
      {
        headers: this.getHeaders(),
      },
    ) as Observable<any>;
  }
}
