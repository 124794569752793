import { GenericReadOnlyService } from '../../services/generic.service';

export class GenericTvItem<T> {
  title = '';
  icon = '';

  private paging = 1;
  perPage = 7;

  isLoading: boolean = true;
  items: T[] = [];

  constructor(public _service: GenericReadOnlyService<T>) {
    this.getPages();
  }

  preSettingData(data: T[]): T[] {
    return data;
  }

  postSettingData(): void {
    undefined;
  }

  getPages(paging = this.paging, perPage = this.perPage) {
    this.isLoading = true;
    this._service?.getPages(paging, perPage).subscribe(
      data => {
        this.items = this.preSettingData(data);
        this.postSettingData();
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      },
    );
  }
}
