// src\app\services\websocket.service.ts
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { ObservableService } from '../core/models/class/service';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import Backend from '../core/backend';
import { isSecure } from '../core';

@Injectable()
export class WebsocketService {
  private readonly maxRetries = 5; // Maximum number of retries
  private websocketSubject: WebSocketSubject<any>;

  constructor() {
    this.websocketSubject = webSocket<any>(this.getUrl() + '/service-changes/').pipe(
      retry(this.maxRetries),
    ) as WebSocketSubject<any>;
  }

  getUrl(): string {
    const address = Backend.url;
    const proto = isSecure(address) ? 'wss' : 'ws';
    // looks something like: 'wss://<ip_addr:port>/ws'
    return `${proto}://${address}/ws`;
  }

  /**
   * Subscribes to the WebSocket and returns an Observable that emits when the service is updated.
   */
  onServiceUpdated(): Observable<ObservableService> {
    return this.websocketSubject.asObservable() as Observable<ObservableService>;
  }

  /**
   * Sends an empty message to the WebSocket endpoint to make sure connection stays alive .
   */
  sendEmptyMessage(): void {
    this.websocketSubject.next('');
  }
}

@Injectable()
export class WebsocketServiceMobile extends WebsocketService {
  constructor() {
    super();
  }
}
