import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ApplicationState } from '../core/services/base.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!ApplicationState.isAuthenticated()) {
      this.router.navigate(['/login']);
      return false;
    }
    // const permissionsGranted = getItem(StorageItem.permissions);
    // TODO: any officer or just eticket permission
    return true;
  }
}
