export * from './OurCommon.module';
export * from './lib/functions';
export * from './lib/local-storage.utils';
import { trigger, style, animate, transition } from '@angular/animations';
export const defaultAnimation = {
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate(500, style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition('* => void', [animate(0, style({ opacity: 0 }))]),
    ]),
  ],
};

export enum SOUNDS {
  TEST_AUDIO = 'TEST_AUDIO',
}
