import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Comment } from '../models/class/comment';
import { GenericReadOnlyRemoteService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class ForumService extends GenericReadOnlyRemoteService<Comment> {
  constructor(protected http: HttpClient) {
    super('/forum/comments', http);
  }
}
