import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationState, BaseService } from './base.service';
import Backend from '../backend';

@Injectable({
  providedIn: 'root',
})
export class GenericReadOnlyService<T> extends BaseService {
  protected prefix = '';
  protected suffix = '';

  constructor(protected http: HttpClient) {
    super();
  }

  public getPages(page: number = 1, perPage: number = 100): Observable<T[]> {
    return this.http.get(
      this.getUrl() + this.prefix + this.suffix + '?page=' + page + '&per_page=' + perPage,
    ) as Observable<T[]>;
  }
}

export class GenericReadOnlyRemoteService<T> extends GenericReadOnlyService<T> {
  constructor(protected prefix: string, protected http: HttpClient, protected suffix = '') {
    super(http);
    this.prefix = prefix;
    this.suffix = suffix; // some endpoint need a "/" at the end
  }

  public getUrl(): string {
    return Backend.remote_url + `/api/municipalities/` + ApplicationState.getMunicipalityId();
  }
}
