import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-polls-tv',
  templateUrl: './polls-tv.component.html',
  styleUrls: ['./polls-tv.component.css'],
})
export class PollsTvComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
