import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageItem, defaultAnimation, isValidPhoneNumber, setItem } from 'src/app/core';
import { ToastLuncherService } from 'src/app/core/services/toast-luncher.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  ...defaultAnimation,
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;
  phone: string = null;
  password: string = null;

  constructor(
    protected router: Router,
    protected _localService: LocalService,
    protected _toastLuncherService: ToastLuncherService,
    public jwtHelper: JwtHelperService,
  ) {}

  ngOnInit() {}

  login(phone_number: string, password: string) {
    if (!phone_number?.length || !isValidPhoneNumber(phone_number) || !password?.length)
      throw new Error('الرجاء ملء كل الفراغات والتثبت من كلمة السر ورقم الهاتف');

    this.isLoading = true;
    this._localService.loginOnlyPhone(phone_number, password).subscribe(
      loginResponse => {
        setItem(StorageItem.token, loginResponse.access);
        this.router.navigate(['/mobile']);
      },
      err => {
        this.isLoading = false;
        throw new Error('ليس لديك الإذن الكافي للتذكرة الإلكترونية');
      },
    );
  }
}
