import { Component, OnInit, Input } from '@angular/core';
import { DossierUpdate } from '../../models/class/dossier';

import { Update } from '../../models/class/update';
@Component({
  selector: 'app-comment-status',
  templateUrl: './comment-status.component.html',
  styleUrls: ['./comment-status.component.css'],
})
export class CommentStatusComponent implements OnInit {
  @Input() update: Update | DossierUpdate;
  @Input() type: string;
  attachment: string;
  constructor() {}

  ngOnInit(): void {
    this.attachment = this.update.attachment || '';
  }
}
