import { SOUNDS } from '..';
import Backend from '../backend';
import { Service } from '../models/class/service';
import { CommentTypes, PollStatusTypes } from '../models/types';
import { ArabicTimeAgoPipe } from '../pipes/arabic-time-ago.pipe';
import { StatusColorPipe } from '../pipes/status-color.pipe';

const timePipe = new ArabicTimeAgoPipe();

export const timeAgo = (date: string | number): string => {
  return timePipe.transform(date.toString());
};

export const getPollStatus = (start: Date | string, end: Date | string) => {
  const today = new Date();
  if (today < new Date(start)) return PollStatusTypes.ENDED;
  if (today > new Date(start) && today < new Date(end)) return PollStatusTypes.IN_PROGRESS;
  return PollStatusTypes.ENDED;
};

export const commentTypeToArabic = (value: CommentTypes | string): string => {
  switch (value) {
    case CommentTypes.REMARK:
      return 'ملاحظة';
    case CommentTypes.QUESTION:
      return 'سؤال';
    // default to SUGGESTION
    default:
      return 'مقترح';
  }
};

const statusColorPipe = new StatusColorPipe();
export const statusColor = (input: string): string => {
  return statusColorPipe.transform(input);
};

export const getCommentTypeIcon = (type: CommentTypes | string, prefix = '') => {
  // defaults to remark
  switch (type) {
    case CommentTypes.QUESTION:
      return './assets/images/ico/' + prefix + 'question.svg';
    case CommentTypes.SUGGESTION:
      return './assets/images/ico/' + prefix + 'suggestion.png';
    default:
      return './assets/images/ico/' + prefix + 'remark.svg';
  }
};

export const monthsSince = (lastUpdate: string): boolean => {
  const today = new Date();
  const updateDate = new Date(lastUpdate.replace('-', ' '));
  let months;
  months = (today.getFullYear() - updateDate.getFullYear()) * 12;
  months -= updateDate.getMonth();
  months += updateDate.getMonth();
  return months <= 0 ? 0 : months;
};

/**
 *
 * @param input string to check if valid
 * @param length the required length of that string
 * @returns boolean
 */
const isValidStringOfNumbers = (input: string, length: number) => {
  const output = input.toString();
  const validStringOfNumbers = new RegExp('^[0-9]+$');
  return validStringOfNumbers.test(output) && output.length === length;
};

export const isValidPhoneNumber = (phone_number: string | number) => {
  phone_number = phone_number.toString().replace(/\s/g, ''); // remove whitespaces;

  if (phone_number.includes('+216')) phone_number = phone_number.replace('+216', '');

  return isValidStringOfNumbers(phone_number, 8);
};

export const getPrefix = (service: Service): string => {
  return service.name[0];
};

export const toArabicDate = (date: string) => {
  return new Date(Date.parse(date)).toLocaleDateString('ar-tn', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};

export function canAutoplayAudio(): boolean {
  return navigator.userAgent.indexOf('Firefox') !== -1;
}

export function isSecure(address_or_domain: string | null | undefined): boolean {
  // cc: wassef
  // please reach me before changing anything about this function
  return (
    typeof address_or_domain === 'string' &&
    address_or_domain?.length > 0 &&
    (address_or_domain?.includes('ngrok') ||
      address_or_domain?.includes('elbaladiya.tn') ||
      address_or_domain?.includes('pmc'))
  );
}
