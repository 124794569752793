import { Component } from '@angular/core';
import Backend from '../../backend';
import { Observable } from 'rxjs';
import { Municipality } from './municipality';
import { isSecure, toArabicDate } from '../..';
import { LocalService } from 'src/app/services/local.service';

@Component({
  styleUrls: [],
  template: '',
})
export class BaseView {
  // a default logo path, should be handled by the backend
  // always exists, defaulting a white/square img
  logo = `${isSecure(Backend.url) ? 'https://' : 'http://'}${Backend.url}/api/media/logo.png`;
  $municipality: Observable<Municipality> = undefined;
  currentTime: string = toArabicDate(new Date().toString());
  timer: any;

  constructor(protected _localService: LocalService) {
    this.$municipality = this._localService?.getMunicipality();
    this.timer = setInterval(() => {
      this.currentTime = toArabicDate(new Date().toString());
    }, 60000);
  }
}
