import { Component, OnInit } from '@angular/core';
import { Complaint } from 'src/app/core/models/class/complaint';
import { GenericTvItem } from 'src/app/core/models/class/GenericTvItem';
import { UpdateTypes } from 'src/app/core/models/types';
import { ComplaintsService } from 'src/app/core/services/complaints.service';

@Component({
  selector: 'app-plaints-tv',
  templateUrl: './plaints-tv.component.html',
  styleUrls: ['./plaints-tv.component.css'],
})
export class PlaintsTvComponent extends GenericTvItem<Complaint> implements OnInit {
  title = 'اﻟﺘﺒﻠﻴﻎ ﻋﻦ ﻣﺸﻜﻞ';
  icon = 'assets/images/plaint-icon.png';

  constructor(protected _complaintService: ComplaintsService) {
    super(_complaintService);
  }

  ngOnInit(): void {}

  preSettingData(data: Complaint[]): Complaint[] {
    return data.filter(
      p => p.updates[0].status === UpdateTypes.PROCESSING || p.updates[0].status === UpdateTypes.ACCEPTED,
    );
  }
}
