import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CommentStatusComponent } from './components/comment-status/comment-status.component';
import { AccessRequestPipe } from './pipes/access-request.pipe';
import { ArabicDatePipe } from './pipes/arabic-date.pipe';
import { ArabicTimeAgoPipe } from './pipes/arabic-time-ago.pipe';
import { ArabicTimeLeftPipe } from './pipes/arabic-time-left.pipe';
import { CommentStatusIconPipe } from './pipes/comment-status-icon.pipe';
import { CommentStatusPipe } from './pipes/comment-status.pipe';
import { ComplaintStatusPipe } from './pipes/complaint-status.pipe';
import { DossierStatusPipe } from './pipes/dossier-status.pipe';
import { PollCountPipe } from './pipes/poll-count.pipe';
import { PollStatusPipe } from './pipes/poll-status.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { StatusColorPipe } from './pipes/status-color.pipe';
import { StatusIconPipe } from './pipes/status-icon.pipe';
import { VoteBtnTextPipe } from './pipes/vote-btn-text.pipe';
import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { ImgloaderComponent } from './components/imgloader/imgloader.component';

@NgModule({
  declarations: [
    CommentStatusComponent,
    EmptyListComponent,
    ImgloaderComponent,
    ComplaintStatusPipe,
    StatusColorPipe,
    DossierStatusPipe,
    AccessRequestPipe,
    SafeUrlPipe,
    StatusIconPipe,
    CommentStatusIconPipe,
    CommentStatusPipe,
    PollStatusPipe,
    ArabicDatePipe,
    ArabicTimeAgoPipe,
    PollCountPipe,
    ArabicTimeLeftPipe,
    VoteBtnTextPipe,
    LoadingAnimationComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule],
  exports: [
    CommentStatusComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    ImgloaderComponent,
    ComplaintStatusPipe,
    StatusColorPipe,
    DossierStatusPipe,
    AccessRequestPipe,
    SafeUrlPipe,
    StatusIconPipe,
    CommentStatusIconPipe,
    CommentStatusPipe,
    PollStatusPipe,
    ArabicDatePipe,
    VoteBtnTextPipe,
    ArabicTimeAgoPipe,
    ArabicTimeLeftPipe,
    PollCountPipe,
    LoadingAnimationComponent,
    EmptyListComponent,
  ],
})
export class OurCommonModule {}
