import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ToastLuncherService {
  constructor() {}

  askForSoundPermission() {
    return Swal.fire({
      padding: '0.5em',
      showConfirmButton: true,
      confirmButtonColor: '#333e63',
      confirmButtonText: 'نعم',
      title: 'التحقق من نظام الصوت',
    });
  }

  success(info: ToastInfo) {
    return Swal.mixin({
      toast: true,
      position: 'bottom-left',
      showConfirmButton: info.showConfirmButton,
      timer: info.timer || 4000,
      customClass: { popup: 'popup' },
    }).fire({
      background: '#e8ffe8',
      padding: '0.5em',
      html: `<div class='html-cont' style='cursor:default;2px solid #dcf7d1'>
        <h5 class='toast-text'>
        ${info.text}
        </h5>
       <div >
       <span aria-hidden="true">&times;</span>
       </div>`,
    });
  }

  alert(info: ToastInfo) {
    return Swal.mixin({
      toast: true,
      position: 'bottom-left',
      showConfirmButton: info.showConfirmButton || false,
      timer: info.timer || 4000,
      customClass: { popup: 'popup' },
    }).fire({
      background: '#ffe8e8',
      padding: '0.5em',
      html: `<div class='html-cont' style='cursor:default;2px solid #f7d1d1'>
        <h5 class='toast-text'>
        ${info.text}
        </h5>
       <div >
       <span aria-hidden="true">&times;</span>
        </div>`,
    });
  }

  info(info: ToastInfo) {
    return Swal.mixin({
      toast: true,
      position: 'bottom-start',
      showConfirmButton: info.showConfirmButton || false,
      timer: info.timer || 4000,
      customClass: { popup: 'popup' },
    }).fire({
      background: '#eff8fe',
      padding: '0.5em',
      html: `<div class='html-cont' style='cursor:default;2px solid #D1EBF7'>
        <h5 class='toast-text'>
        ${info.text}
        </h5>
       <div >
       <span aria-hidden="true">&times;</span>
        </div>`,
    });
  }

  handleError(error) {
    if (error?.message?.includes('ا') || !environment.production)
      this.alert({
        showConfirmButton: false,
        timer: 8000,
        text: error.message,
      });
    console.log(error);
  }
}

export interface ToastInfo {
  showConfirmButton?: boolean;
  timer?: number;
  text: string;
}
